import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { DateObject } from "react-multi-date-picker";

import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import CustomMultiDateSelect from "components/Custom/CustomMultiDateSelect";
import SupportEvents from "views/SupportEvents/SupportEventList";
import CustomStatus from "components/Custom/CustomStatus";
import {
  getClient,
  postClientEnvironmentActionRequest,
} from "graphql/queries.js";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { convertDateListToString } from "utilities/dateUtilities";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";

function Environments({ user }) {
  // Hooks
  const notify = useContext(NotificationAlertContext);
  const history = useHistory();

  const { clientId } = useParams();
  const getLastEvaluated = (difference) => {
    let lastEvaluated = "Just now";
    const day = Math.floor(difference / (1000 * 60 * 60 * 24));
    if (day === 0) {
      difference = difference % (1000 * 60 * 60 * 24);
      const hour = Math.floor(difference / (1000 * 60 * 60));
      if (hour === 0) {
        difference = difference % (1000 * 60 * 60);
        const mint = Math.floor(difference / (1000 * 60));
        if (mint > 0) {
          lastEvaluated = mint === 1 ? `${mint} min ago` : `${mint} mins ago`;
        }
      } else {
        lastEvaluated = hour === 1 ? `${hour} hour ago` : `${hour} hours ago`;
      }
    } else {
      lastEvaluated = day === 1 ? `${day} day ago` : `${day} days ago`;
    }
    return lastEvaluated;
  };

  const clientEnvironmentActionOptionsList = [
    { key: "none", value: "No Action" },
    { key: "suppress", value: "Suppress Support Events" },
    { key: "unsuppress", value: "Unsuppress Support Events" },
    ,
    { key: "db", value: "Delete Database Entries" },
    { key: "resource", value: "Delete Cloud Infrastructure (Internal Only)" },
    {
      key: "all",
      value: "Delete Cloud Infrastructure and Database Entries (Internal Only)",
    },
  ];

  const clientEnvironmentActionOptionsInternal = [
    { key: "none", value: "No Action" },
    { key: "suppress", value: "Suppress Support Events" },
    { key: "unsuppress", value: "Unsuppress Support Events" },
    { key: "db", value: "Delete Database Entries" },
    { key: "resource", value: "Delete Cloud Infrastructure" },
    { key: "all", value: "Delete Cloud Infrastructure and Database Entries" },
  ];

  const clientEnvironmentActionOptionsExternal = [
    { key: "none", value: "No Action" },
    { key: "suppress", value: "Suppress Support Events" },
    { key: "unsuppress", value: "Unsuppress Support Events" },
    { key: "db", value: "Delete Database Entries" },
  ];

  const [multiSelected, setMultiSelected] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [
    clientEnvironmentActionModalOpen,
    setClientEnvironmentActionModalOpen,
  ] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [jobRanAt, setJobRanAt] = useState(null);
  // const [activityFeedTableData, setActivityFeedTableData] = useState([]);
  // const [startDate, setStartDate] = useState(moment().subtract(1, "day"));
  // const [endDate, setEndDate] = useState(moment());
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [metricDateList, setMetricDateList] = useState([new DateObject()]);
  const groupname =
    user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const [allEnvReady, setAllEnvReady] = useState(true);

  // Pagination Starts
  const columnMapping = {
    Status: "client_environment_status",
    "Environment Id": "client_environment_id",
    "Deploy Status": "deploy_status",
    "Support Event Suppressed": "suppress_support_event",
    "Spoke Name": "spoke_name",
    "Spoke Type": "spoke_type",
    Region: "region",
    "AWS Account": "aws_account",
    "Last Environment Update": "created_at",
    Channels: "channels_count",
    Received: "received_messages",
    Queued: "queued_messages",
    Filtered: "filtered_messages",
    Errored: "errored_messages",
    "Sent/Transformed": "sent_messages",
    "Avg Process Time (sec)": "avg_process_time",
  };
  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Environment Id");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchClient = async (variables = {}) => {
    try {
      setShowLoader(true);
      const params = {
        client_id: clientId,
        metric_date_list: convertDateListToString(metricDateList),
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(
        graphqlOperation(getClient, variables)
      );
      if (response.data?.getClient?.success) {
        const clientData = _.get(response,'data.getClient.body',[]);
        const data = _.get(response,'data.getClient.body.client_environments_body',[]);
        const totalCount = _.get(response,'data.getClient.body.client_environments_body.total_count',0);
        setTotalCount(totalCount);
        setClientData(clientData);
        if (data.client_environments.length > 0) {
          setJobRanAt(data.client_environments[0].last_calculated);
        } else {
          setJobRanAt(null);
        }
        const now = new Date();
        const environments = data.client_environments.map((environmentData) => {
          const lastUpdated = new Date(
            environmentData.updated_at || environmentData.created_at
          );
          const difference = now - lastUpdated;
          const lastEvaluated = getLastEvaluated(difference);
          if (
            allEnvReady &&
            !isStatusInAccessibleState(environmentData.deploy_status)
          ) {
            setAllEnvReady(false);
          }
          return {
            ...environmentData,
            lastEvaluated,
          };
        });
        setTableData(environments);
      } else {
        console.error(response.data?.getClient?.message);
      }
      return response.data?.getClient?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  function resetSelect(num_select, defaultSelect) {
    let new_list = [];
    for (
      let num_select_index = 0;
      num_select_index < num_select;
      num_select_index++
    ) {
      new_list.push(clientEnvironmentActionOptionsList[defaultSelect]);
    }
    setMultiSelected(new_list);
  }

  function handleMultiSelect(key, event, index) {
    let new_list = [];
    for (
      let select_index = 0;
      select_index < multiSelected.length;
      select_index++
    ) {
      if (select_index == index) {
        new_list.push({ key: key, value: event.target.text });
      } else {
        new_list.push(multiSelected[select_index]);
      }
    }
    setMultiSelected(new_list);
  }

  function handleClientEnvironmentActionAllSelect(key, client_env_list) {
    let selected_index = clientEnvironmentActionOptionsInternal.findIndex(
      (x) => x.key === key
    );
    let new_list = [];
    for (
      let client_env_index = 0;
      client_env_index < client_env_list.length;
      client_env_index++
    ) {
      if (
        client_env_list[client_env_index].spoke_type != 0 &&
        selected_index > 3
      ) {
        new_list.push(multiSelected[client_env_index]);
      } else {
        new_list.push(clientEnvironmentActionOptionsInternal[selected_index]);
      }
      setMultiSelected(new_list);
    }
  }

  const handleClientEnvironmentActionSubmit = async (
    client_id,
    client_environment_list,
    user_id
  ) => {
    let payload = {};
    payload.client_id = client_id;
    payload.user_id = user_id;

    let suppress_list = [];
    let unsuppress_list = [];
    let delete_resource_list = [];
    let delete_db_hub_list = [];
    let delete_db_spoke_list = [];

    for (
      let clientEnvironmentActionOptionIndex = 0;
      clientEnvironmentActionOptionIndex < multiSelected.length;
      clientEnvironmentActionOptionIndex++
    ) {
      if (multiSelected[clientEnvironmentActionOptionIndex].key == "suppress") {
        suppress_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "unsuppress"
      ) {
        unsuppress_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "resource"
      ) {
        delete_resource_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "db"
      ) {
        delete_db_hub_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
        if (
          client_environment_list[clientEnvironmentActionOptionIndex]
            .spoke_type == 0
        ) {
          delete_db_spoke_list.push(
            client_environment_list[clientEnvironmentActionOptionIndex]
              .client_environment_id
          );
        }
      } else if (
        multiSelected[clientEnvironmentActionOptionIndex].key == "all"
      ) {
        delete_resource_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
        delete_db_hub_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
        delete_db_spoke_list.push(
          client_environment_list[clientEnvironmentActionOptionIndex]
            .client_environment_id
        );
      }
    }

    payload.suppress_list = suppress_list;
    payload.unsuppress_list = unsuppress_list;
    payload.delete_resource_list = delete_resource_list;
    payload.delete_db_hub_list = delete_db_hub_list;
    payload.delete_db_spoke_list = delete_db_spoke_list;

    try {
      if (
        suppress_list.length == 0 &&
        unsuppress_list.length == 0 &&
        delete_resource_list.length == 0 &&
        delete_db_hub_list.length == 0
      ) {
        notify(
          "info",
          "Client Environment request was not sent because no actions were changed."
        );
      } else {
        const response = await API.graphql(
          graphqlOperation(postClientEnvironmentActionRequest, payload)
        );

        if (response.data?.postClientEnvironmentActionRequest?.success) {
          notify(
            "success",
            "Successfully sent client environment action request."
          );
          history.push("/admin/clients");
        } else {
          notify(
            "warning",
            "Failed to send client environment action request."
          );
        }
      }
    } catch (e) {
      notify(
        "warning",
        "Failed to get response after client environment action request."
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      const filterObj = {
        offSet: 0,
        limit: currentLimit,
      };
      const isSuccess = await fetchClient(filterObj);
      if (!isSuccess) {
        notify("danger");
        groupname === "internal" ? history.goBack() : setShowError(true);
      }
    }
    fetchData();
    /* const interval = setInterval(() => {
      const now = new Date();
      setTableData((oldData) => {
        return oldData.map((environmentData) => {
          const updated_at =
            environmentData.updated_at || environmentData.createdat;
          const difference = now - new Date(updated_at);
          const lastEvaluated = getLastEvaluated(difference);
          return { ...environmentData, lastEvaluated };
        });
      });
    }, 60 * 1000);
    return () => clearInterval(interval); */
  }, []);

  // const userClientId = user.attributes["custom:clientId"];
  /* const { loading, error, data } = useQuery(GET_CLIENT_ENV_DETAIL_QUERY, {
    variables: {
      clientId,
      metric_date_list: metricDateList,
    },
    pollInterval: 60 * 1000,
    fetchPolicy: "no-cache",
    onCompleted: (environmentsData) => {
      if (environmentsData.getClientEnv.client_envs.length > 0) {
        setJobRanAt(
          environmentsData.getClientEnv.client_envs[0].last_calculated
        );
      } else {
        setJobRanAt(null);
      }
      const now = new Date();
      const environments = environmentsData.getClientEnv.client_envs
        ? environmentsData.getClientEnv.client_envs.map((environmentData) => {
            const updated_at =
              environmentData.updated_at || environmentData.createdat;
            const lastUpdated = new Date(updated_at);
            const difference = now - lastUpdated;
            const lastEvaluated = getLastEvaluated(difference);
            return {
              ...environmentData,
              lastEvaluated,
            };
          })
        : [];
      setTableData(environments);
    },
  }); */

  /* const [
    fetchActivityFeed,
    {
      called: activityFeedCalled,
      loading: activityFeedLoading,
      error: activityFeedError,
    },
  ] = useLazyQuery(GET_ACTIVITY_FEED_QUERY, {
    onCompleted: (data) => {
      if (data && data.getActivityFeed) {
        setActivityFeedTableData(data.getActivityFeed);
      }
    },
  });

  if (
    !activityFeedCalled &&
    (groupname !== "external" || clientId === userClientId)
  ) {
    fetchActivityFeed({
      variables: {
        clientId,
        startDate: startDate,
        endDate: endDate,
      },
      fetchPolicy: "no-cache",
    });
  } */

  if (showLoader) return <Spinner animation="grow" />;

  if (showError)
    return `Could not load the page at this moment. Please try again later.`;

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <h3 style={{ textAlign: "center" }}>Client Details</h3>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h5>Client Name: {clientData?.client_name}</h5>
          <h5>Client Id: {clientData?.client_id}</h5>
          <h5>
            Client Status: <CustomStatus value={clientData?.client_status} />
          </h5>
        </Col>
      </Row>
      {groupname === "internal" && (
        <Button
          id="delete-client"
          variant="info"
          className="btn-round"
          type="button"
          disabled={clientData?.client_environments_body?.client_environments?.length == 0}
          onClick={() => {
            resetSelect(clientData?.client_environments_body?.client_environments?.length, 0);
            setClientEnvironmentActionModalOpen(true);
          }}
        >
          <i className="fas fa-running"></i>Client Environment Action
        </Button>
      )}
      <hr></hr>
      <Row>
        <Col md="3">
          <label
            style={{
              paddingRight: "10px",
            }}
          >
            Select Date(s):
          </label>
          <CustomMultiDateSelect
            value={metricDateList}
            onChange={setMetricDateList}
          ></CustomMultiDateSelect>
        </Col>
        <Col md="2">
          <Button
            variant="info"
            className="btn-round"
            type="button"
            onClick={async () => {
              const filterObj = {
                offSet: 0,
                limit: currentLimit,
              };
              setCurrentOffset(0);
              const isSuccess = await fetchClient(filterObj);
              if (isSuccess) notify("success");
              else notify("danger");
            }}
          >
            {" "}
            Refresh
          </Button>
        </Col>
        <Col md="4">
          {jobRanAt
            ? `Last calculated at ${new Date(jobRanAt).toLocaleString("en-US")}`
            : `No data to show for selected date(s)`}
        </Col>
        {groupname === "internal" && (
          <Col md="3">
            <Link
              className="btn btn-round btn-info"
              style={{ float: "right" }}
              to={{
                pathname: `/admin/clients/${clientId}/environments/create-environment`,
              }}
            >
              <i className="fas fa-plus"></i> Add Environment
            </Link>
          </Col>
        )}
      </Row>
      <Row>
        {groupname === "internal" && (
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h5">
                  Number of Environments:{" "}
                  {totalCount}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={tableData}
                      totalCount={totalCount}
                      functionCallBack={fetchClient}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      columns={[
                        {
                          Header: "Status",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomStatus
                                value={row.client_environment_status}
                              />
                            );
                          },
                          accessor: "client_environment_status",
                          filterable: false,
                          showTooltip: false,
                          placement: "right",
                          maxWidth: 80,
                        },
                        {
                          Header: "Environment",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <Link
                                to={{
                                  pathname: `/admin/clients/${clientId}/environments/${row.client_environment_id}`,
                                }}
                              >
                                {row.client_environment_name}
                              </Link>
                            );
                          },
                          accessor: "client_environment_name",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Deploy Status",
                          accessor: "deploy_status",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Support Event Suppressed",
                          accessor: (row) => {
                            return row.suppress_support_event ? "Yes" : "No";
                          },
                          filterable: false,
                          showTooltip: false,
                          minWidth: 220,
                        },
                        {
                          Header: "Spoke Name",
                          accessor: "spoke_name",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Spoke Type",
                          accessor: "spoke_type",
                          filterable: false,
                          showTooltip: false,
                        },
                        {
                          Header: "Region",
                          accessor: "region",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "AWS Account",
                          accessor: "aws_account",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Last Environment Update",
                          accessor: "lastEvaluated",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 210,
                        },
                      ]}
                      hidePagination={false}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h5">Environment Metrics</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-responsive p-0">
                  <ReactTable
                    data={tableData}
                    totalCount={totalCount}
                    functionCallBack={fetchClient}
                    setStateForPagnination={setStateForPagnination}
                    currentLimit={currentLimit}
                    currentOffset={currentOffset}
                    ascOrDescValue={ascOrDescValue}
                    sortValue={sortValue}
                    numberOfRowsData={numberOfRowsData}
                    columnMapping={columnMapping}
                    columns={[
                      {
                        Header: "Status",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          return (
                            <CustomStatus
                              value={row.client_environment_status}
                            />
                          );
                        },
                        accessor: "client_environment_status",
                        filterable: false,
                        showTooltip: false,
                        maxWidth: 80,
                      },
                      {
                        Header: "Environment",
                        Cell: (cell) => {
                          const row = cell.row.original;
                          return (
                            <Link
                              to={{
                                pathname: `/admin/clients/${clientId}/environments/${row.client_environment_id}`,
                              }}
                            >
                              {row.client_environment_name}
                            </Link>
                          );
                        },
                        accessor: "client_environment_name",
                        filterable: false,
                        showTooltip: true,
                        minWidth: 200,
                      },
                      {
                        Header: "Channels",
                        accessor: "channels_count",
                        filterable: false,
                        showTooltip: false,
                      },
                      {
                        Header: "Received",
                        accessor: (row) => {
                          return row.received_messages
                            ? row.received_messages
                            : 0;
                        },
                        filterable: false,
                        showTooltip: false,
                      },
                      {
                        Header: "Queued",
                        accessor: (row) => {
                          return row.queued_messages ? row.queued_messages : 0;
                        },
                        filterable: false,
                        showTooltip: false,
                      },
                      {
                        Header: "Filtered",
                        accessor: (row) => {
                          return row.filtered_messages
                            ? row.filtered_messages
                            : 0;
                        },
                        filterable: false,
                        showTooltip: false,
                      },
                      {
                        Header: "Errored",
                        accessor: (row) => {
                          return row.errored_messages
                            ? row.errored_messages
                            : 0;
                        },
                        filterable: false,
                        showTooltip: false,
                      },
                      {
                        Header: "Sent/Transformed",
                        accessor: (row) => {
                          return row.sent_messages ? row.sent_messages : 0;
                        },
                        filterable: false,
                        showTooltip: false,
                        minWidth: 180,
                      },
                      {
                        Header: "Avg Process Time (sec)",
                        accessor: (row) => {
                          return (
                            Math.round(10000 * row.avg_process_time) / 10000
                          );
                        },
                        filterable: false,
                        showTooltip: false,
                        minWidth: 180,
                      },
                    ]}
                    hidePagination={false}
                  />
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <SupportEvents
          inputProps={{
            client_id: clientData?.client_id,
            client_name: clientData?.client_name,
          }}
        ></SupportEvents>
      </Row>
      {/* <Row>
            <Col md="2">
              <CustomReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Date",
                  value: startDate ? startDate.format("MM/DD/YYYY h:mm A") : "",
                }}
                timeFormat={true}
                value={startDate}
                onChange={(value) => {
                  setStartDate(value);
                }}
              ></CustomReactDatetime>
            </Col>
            <Col md="2">
              <CustomReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "End Date",
                  value: endDate ? endDate.format("MM/DD/YYYY h:mm A") : "",
                }}
                timeFormat={true}
                value={endDate}
                onChange={(value) => {
                  setEndDate(value);
                }}
              ></CustomReactDatetime>
            </Col>
            <Col md="2">
              <Button
                variant="info"
                className="btn-round"
                type="button"
                onClick={() => {
                  fetchActivityFeed({
                    variables: {
                      clientId,
                      startDate: startDate,
                      endDate: endDate,
                    },
                    fetchPolicy: "no-cache",
                  });
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h5">
                    Activity feed for{" "}
                    {new Date(startDate).toLocaleDateString("en-US", options)}{" "}
                    through{" "}
                    {new Date(endDate).toLocaleDateString("en-US", options)}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body className="table-responsive p-0">
                      <ReactTable
                        data={activityFeedTableData}
                        columns={[
                          {
                            Header: "Event Start Date",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              if (!row.event_start_date) return;
                              const date = new Date(
                                row.event_start_date
                              ).toLocaleString("en-US", options);
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{date}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>{date}</div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "event_start_date",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Event Reference",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip>{row.event_reference}</Tooltip>
                                  }
                                >
                                  <div style={ellipsisStyle}>
                                    {row.event_reference}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "event_reference",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Comment Date",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              if (!row.comment_date) return;
                              const date = new Date(
                                row.comment_date
                              ).toLocaleString("en-US", options);
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{date}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>{date}</div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "comment_date",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Comments",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip>{row.comments}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>
                                    {row.comments}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "comments",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "User",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip>{row.user_id}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>{row.user_id}</div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "user_id",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Environment",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip>
                                      {row.client_environment_id}
                                    </Tooltip>
                                  }
                                >
                                  <div style={ellipsisStyle}>
                                    {row.client_environment_id}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "client_environment_id",
                            filterable: false,
                            showTooltip:false
                          },
                          {
                            Header: "Channel",
                            Cell: (cell) => {
                              const row = cell.row.original;
                              return (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip>{row.channel_id}</Tooltip>}
                                >
                                  <div style={ellipsisStyle}>
                                    {row.channel_id}
                                  </div>
                                </OverlayTrigger>
                              );
                            },
                            accessor: "channel_id",
                            filterable: false,
                            showTooltip:false
                          },
                        ]}
                        hidePagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
      <Modal
        toggle={() =>
          setClientEnvironmentActionModalOpen(!clientEnvironmentActionModalOpen)
        }
        size="lg"
        isOpen={clientEnvironmentActionModalOpen}
        scrollable={false}
      >
        <div className="modal-header">
          <div className="modal-title" id="clientEnvironmentActionOptionsList">
            <h5>Select Client Environment Action Options</h5>
            {!allEnvReady && (
              <h5 className="text-danger">
                *Note: One or more environments are currently deploying and
                can't be changed.
              </h5>
            )}
          </div>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setClientEnvironmentActionModalOpen(false)}
          >
            <span aria-hidden={true}>x</span>
          </button>
        </div>
        <ModalHeader>
          <DropdownButton
            id="dropdown-basic-button-all"
            variant="info"
            className="floatRight"
            disabled={!allEnvReady}
            onSelect={(key) =>
              handleClientEnvironmentActionAllSelect(
                key,
                clientData?.client_environments_body?.client_environments
              )
            }
            title="Apply to All "
          >
            {clientEnvironmentActionOptionsList.map((item, index) => {
              return (
                <Dropdown.Item key={index} eventKey={item.key}>
                  {item.value}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>Client Environment</th>
                <th>Environment Type</th>
                <th>Select Action to Take</th>
              </tr>
            </thead>
            <tbody>
              {clientData?.client_environments_body?.client_environments.map((client_env, index) => {
                if (client_env.spoke_type == 0) {
                  return (
                    <tr key={index}>
                      <td>{client_env.client_environment_name}</td>
                      <td>Internal</td>
                      <td>
                        <DropdownButton
                          id={`dropdown-basic-button-${index}`}
                          variant="info"
                          className="floatRight"
                          disabled={
                            !isStatusInAccessibleState(client_env.deploy_status)
                          }
                          onSelect={(key, event) =>
                            handleMultiSelect(key, event, index)
                          }
                          title={
                            multiSelected[index]?.value ||
                            clientEnvironmentActionOptionsInternal[0].value
                          }
                        >
                          {clientEnvironmentActionOptionsInternal.map(
                            (item, index) => {
                              return (
                                <Dropdown.Item key={index} eventKey={item.key}>
                                  {item.value}
                                </Dropdown.Item>
                              );
                            }
                          )}
                        </DropdownButton>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index}>
                      <td>{client_env.client_environment_id}</td>
                      <td>External</td>
                      <td>
                        <DropdownButton
                          id={`dropdown-basic-button-${index}`}
                          variant="info"
                          className="floatRight"
                          onSelect={(key, event) =>
                            handleMultiSelect(key, event, index)
                          }
                          title={
                            multiSelected[index]?.value ||
                            clientEnvironmentActionOptionsExternal[0].value
                          }
                        >
                          {clientEnvironmentActionOptionsExternal.map(
                            (item, index) => {
                              return (
                                <Dropdown.Item key={index} eventKey={item.key}>
                                  {item.value}
                                </Dropdown.Item>
                              );
                            }
                          )}
                        </DropdownButton>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            type="button"
            onClick={() => setClientEnvironmentActionModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              handleClientEnvironmentActionSubmit(
                clientData.client_id,
                clientData?.client_environments_body?.client_environments,
                user.attributes.sub
              );
              setClientEnvironmentActionModalOpen(false);
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default withAuthenticator(Environments, { hideSignUp: true });
