import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
  Nav,
  Tab,
} from "react-bootstrap";
import {
  getList,
  getClientEnvironment,
  getClientEnvironmentAuth,
  getEnvironmentConfiguration,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import * as moment from "moment";

function ChannelSelector({
  selectedChannels,
  selectedUsername,
  onEnvironmentSelectionChange,
  onUsernameSelectionChange,
  onChannelSelectionChange,
  onAuthTypeChange,
  isUpdatingAuth,
  setConfiguration,
  setUpdatedAt,
}) {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const authTypeOptions = [
    { label: "BASIC", value: "BASIC" },
    { label: "API KEY", value: "API_KEY" },
    { label: "SFTP", value: "SFTP" },
  ];

  // Create states for top level dropdowns
  const [clientOptions, setClientOptions] = useState([]);
  const [environmentOptions, setEnvironmentOptions] = useState([]);
  const [usernameOptions, setUsernameOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [channelsData, setChannelsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedAuthType, setSelectedAuthType] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const fetchEnvironmentConfiguration = async (type) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getEnvironmentConfiguration, {
          clientEnvironmentId: selectedEnvironment.value,
        })
      );
      if (response.data?.getEnvironmentConfiguration?.success) {
        let config = JSON.parse(
          response.data?.getEnvironmentConfiguration?.body?.response
        );
        const updated_at = config.updated_at;
        config = config.client_environment_configuration;
        setConfiguration(config);
        setUpdatedAt(updated_at);
        setUsernameOptions(getSFTPUsernameOptions(config.sftp_users));
      } else {
        console.error(response.data?.getEnvironmentConfiguration?.message);
      }
      return response.data?.getEnvironmentConfiguration?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };
  const getUsernameOptions = (authList, authType) => {
    return authList.map((auth, index) => {
      return {
        label: auth.user_name,
        user_name: auth.user_name,
        password: auth.password,
        public_key: auth.public_key,
        api_key: auth.api_key,
        channels: auth.routes.map((channel) => {
          return {
            label: `${channel} , ${authType}`,
            value: channel,
            type: authType,
          };
        }),
        index,
      };
    });
  };

  const getSFTPUsernameOptions = (SFTPUsers) => {
    return SFTPUsers.map((x) => {
      return {
        label: x.user_name,
        user_name: x.user_name,
        channelId: x.channel_id,
      };
    });
  };

  // Fetch options for clients, environments and channels
  const fetchList = async (type, id) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getList, { type, id })
      );
      if (response.data?.getList?.success) {
        const options = response.data.getList
          ? response.data.getList.body
              .map((client) => {
                const option = {
                  value: client.id,
                  label: client.name,
                };
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];
        if (type === "CLIENTS") {
          setClientOptions(options);
        } else if (type === "ENVIRONMENTS") {
          setEnvironmentOptions(options);
        } else if (type === "CHANNELS") {
          setChannelOptions(options);
        }
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchClientEnvironment = async (environmentId) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getClientEnvironment, {
          client_environment_id: environmentId,
          start_date: undefined,
          end_date: undefined,
          metric_date_list: JSON.stringify([moment().format("YYYY-MM-DD")]),
        })
      );
      if (response.data?.getClientEnvironment?.success) {
        const data =
          response.data.getClientEnvironment.body?.client_environment_data;
        const channels = [];
        for (const channel of data.channels) {
          if (channel.mcc_channel_type === "SFTP") {
            channels.push({
              label: `${channel.channel_name} , ${channel.mcc_channel_type}`,
              value: channel.channel_name,
              type: channel.mcc_channel_type,
              channelId: channel.channel_id,
            });
          }
          if (channel.mc_auth_type) {
            channels.push({
              label: `${channel.channel_name} , ${channel.mc_auth_type}`,
              value: channel.channel_name,
              type: channel.mc_auth_type,
            });
          }
        }
        setChannelsData(channels);
        setChannelOptions(channels);
      } else {
        console.error(response.data?.getClientEnvironment?.message);
      }
      return response.data?.getClientEnvironment?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const fetchClientEnvironmentAuth = async (
    client_environment_id,
    auth_type
  ) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getClientEnvironmentAuth, {
          client_environment_id,
          auth_type,
        })
      );
      if (response.data?.getClientEnvironmentAuth?.success) {
        const data = JSON.parse(
          response.data.getClientEnvironmentAuth.body.response
        );
        if (auth_type === "API_KEY") {
          setUsernameOptions(getUsernameOptions(data.api_key_auth, auth_type));
        } else if (auth_type === "BASIC") {
          setUsernameOptions(getUsernameOptions(data.basic_auth, auth_type));
        }
        onAuthTypeChange(data);
      } else {
        console.error(response.data?.getClientEnvironmentAuth?.message);
        notify("danger", `Could not fetch client environment auth details.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch client environment auth details.`);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchList("CLIENTS");
  }, []);

  const reset = () => {
    // onReset();
    setEnvironmentOptions([]);
    setChannelOptions([]);
    setChannelsData([]);
    setSelectedClient(null);
    onEnvironmentSelectionChange(null);
    onUsernameSelectionChange(null);
    setSelectedEnvironment(null);
    setSelectedAuthType(null);
    onChannelSelectionChange(null);
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      <Container fluid>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Channel Selector</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="4">
                <Form.Group>
                  <label>Client</label>
                  <Select
                    id="client"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="clinetList"
                    value={selectedClient}
                    onChange={(event) => {
                      fetchList("ENVIRONMENTS", event.value);
                      setSelectedClient(event);
                      onEnvironmentSelectionChange(null);
                      setSelectedEnvironment(null);
                      setSelectedAuthType(null);
                      onChannelSelectionChange(null);
                    }}
                    options={clientOptions}
                    placeholder="Select Client"
                  />
                </Form.Group>
              </Col>
              {selectedClient && (
                <Col md="4">
                  <Form.Group>
                    <label>Client Environment</label>
                    <Select
                      id="environment"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="environmentList"
                      value={selectedEnvironment}
                      onChange={(event) => {
                        fetchClientEnvironment(event.value);
                        onEnvironmentSelectionChange(event.value);
                        setSelectedEnvironment(event);
                        setSelectedAuthType(null);
                        onChannelSelectionChange(null);
                      }}
                      options={environmentOptions}
                      placeholder="Select Environment"
                    />
                  </Form.Group>
                </Col>
              )}
              {selectedEnvironment && (
                <Col md="4">
                  <Form.Group>
                    <label>Auth Type</label>
                    <Select
                      id="authtype"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="authType"
                      value={selectedAuthType}
                      onChange={(event) => {
                        if (event.value !== "SFTP") {
                          fetchClientEnvironmentAuth(
                            selectedEnvironment?.value,
                            event.value
                          );
                        } else if (event.value === "SFTP") {
                          fetchEnvironmentConfiguration(event.value);
                          onAuthTypeChange({});
                        }
                        if (isUpdatingAuth) {
                          onUsernameSelectionChange(null);
                        }
                        setChannelOptions(
                          channelsData.filter(
                            (option) => option.type === event.value
                          )
                        );
                        onChannelSelectionChange(null);
                        setSelectedAuthType(event);
                      }}
                      options={authTypeOptions}
                      placeholder="Select Auth Type"
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              {selectedAuthType && (
                <>
                  {isUpdatingAuth && selectedAuthType && (
                    <Col md="4">
                      <Form.Group>
                        {selectedAuthType.value === "SFTP" ? (
                          <label>SFTP User</label>
                        ) : (
                          <label>User Name</label>
                        )}
                        <Select
                          id="username"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="username"
                          value={selectedUsername}
                          onChange={(event) => {
                            if (selectedAuthType.value === "SFTP") {
                              const channels = channelOptions.find((x) => {
                                return x.channelId === event.channelId;
                              });
                              onChannelSelectionChange(channels);
                            } else {
                              onChannelSelectionChange(event.channels);
                            }
                            onUsernameSelectionChange(event);
                          }}
                          options={usernameOptions}
                          placeholder={
                            selectedAuthType.value === "SFTP"
                              ? "Select SFTP User"
                              : "Select User Name"
                          }
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {(!isUpdatingAuth || selectedUsername) && (
                    <Col md="8">
                      <Form.Group>
                        <label>Channels</label>
                        <Select
                          id="channels"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="channelList"
                          closeMenuOnSelect={false}
                          isDisabled={
                            isUpdatingAuth && selectedAuthType.value === "SFTP"
                          }
                          isMulti={selectedAuthType.value !== "SFTP"}
                          value={selectedChannels}
                          onChange={(event) => {
                            onChannelSelectionChange(event);
                          }}
                          options={channelOptions}
                          isClearable={false}
                          placeholder="Select Channels"
                        />
                      </Form.Group>
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button className="btn-fill" variant="warning" onClick={reset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default ChannelSelector;
