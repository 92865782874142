/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://od6y7uusqnhmlkev4nbyqs4dwe.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:b49be184-51ca-400c-a82a-caff5fb7ad2e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_b6p2diSld",
    "aws_user_pools_web_client_id": "3a7246m6vb1mclu9fj0ukc3b94",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "mcchubdashboard-20240725215149-hostingbucket-devhubtest",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://drqmz1dod8l4q.cloudfront.net"
};


export default awsmobile;
