import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";

import { NotificationAlertContext } from "contexts/notificationAlertContext";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import { getEventAudits, getAuditSchema } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import SweetAlert from "react-bootstrap-sweetalert";

import moment from "moment";
const linkCustom = {
  position: "relative",
  top: "2px",
};
const sweetAlertCustom = {
  display: "block",
  marginTop: "-100px",
  fontSize: "14px",
  overflow: "auto",
  textAlign: "left",
  width: "80%",
};
function AuditList() {
  // Contexts
  const notify = useContext(NotificationAlertContext);
  // States
  const [tableData, setTableData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [auditEventType, setAuditEventType] = useState([]);
  const [eventActionCode, setEventActionCode] = useState([]);
  const [eventOutcomeCode, setEventOutcomeCode] = useState([]);
  const [eventTypeCode, setEventTypeCode] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [selectedEventActionCodes, setSelectedEventActionCodes] = useState([]);
  const [selectedEventOutcomeCodes, setSelectedEventOutcomeCodes] = useState(
    []
  );
  const [selectedEventTypeCodes, setSelectedEventTypeCodes] = useState([]);
  const [userId, setUserId] = useState("");

  // Pagination Starts
  const columnMapping = {
    "Event Type Code": "event_type_code_text",
    "Event Audit": "audit_event_type_text",
    "Event Action Code": "event_action_code_text",
    "Event Outcome": "event_outcome_text",
    "Email Id": "email_id",
    "Remote Ip": "remote_ip",
    "Event Timestamp": "event_timestamp",
  };

  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Event Timestamp");
  const [auditParams, setAuditParams] = useState({});

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchAuditSchema = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(graphqlOperation(getAuditSchema, {}));
      if (response.data?.getAuditSchema?.success) {
        setAuditEventType(
          response.data?.getAuditSchema?.body?.audit_event_type
        );
        setEventActionCode(
          response.data?.getAuditSchema?.body?.event_action_code
        );
        setEventOutcomeCode(
          response.data?.getAuditSchema?.body?.event_outcome_code
        );
        setEventTypeCode(response.data?.getAuditSchema?.body?.event_type_code);
      } else {
        console.error(response.data?.getAuditSchema?.message);
        notify("danger", "Could not fetch list - " + type);
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not fetch list - " + type);
    } finally {
      setShowLoader(false);
    }
  };

  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const setParticipantObject = async (obj) => {
    setAlert(
      <SweetAlert
        onConfirm={() => hideAlert()}
        style={sweetAlertCustom}
        title="Participant Object Details"
        confirmBtnBsStyle="info"
        confirmBtnText="Ok"
        focusCancelBtn
      >
        <textarea
          style={{
            minWidth: "100%",
            minHeight: "200px",
            background: "#f7f7f7",
            color: "black",
            cursor: "default",
          }}
          disabled
          className="form-control"
          value={JSON.stringify(JSON.parse(obj), null, 2)}
        ></textarea>
      </SweetAlert>
    );
  };

  const fetchAuditEvents = async (params = {}) => {
    try {
      const auditVariables = { ...auditParams, ...params };
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getEventAudits, auditVariables)
      );
      if (response.data?.getEventAudits?.success) {
        const auditEventList =
          response.data.getEventAudits?.body?.event_audit_data;
        const total_count = response.data.getEventAudits?.body?.total_count;
        setTotalCount(total_count);
        const auditEventListData = auditEventList?.map((y, key) => {
          return {
            event_type_code_text: y.event_type_code_text,
            audit_event_type_text: y.audit_event_type_text,
            event_action_code_text: y.event_action_code_text,
            event_outcome_text: y.event_outcome_text,
            email_id: y.email_id,
            remote_ip: y.remote_ip,
            event_timestamp: moment(y.event_timestamp).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            actions: y.participant_object_detail ? (
              <p
                onClick={() =>
                  setParticipantObject(y.participant_object_detail)
                }
              >
                <button
                  className="btn-social btn-link btn btn-twitter"
                  style={linkCustom}
                >
                  View Object
                </button>
              </p>
            ) : (
              <p>No Error Found</p>
            ),
            date_received: new Date(y.date_received).toLocaleString(),
            raw_message_bytes: y.raw_message_bytes,
          };
        });
        setTableData(auditEventListData);
        notify("success");
      } else {
        console.error(response.data?.getEventAudits?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmit = async () => {
    if (!selectedEventTypeCodes || !selectedEventTypeCodes.length) {
      if (!selectedEndDate || !selectedStartDate || !userId) {
        notify(
          "danger",
          "Select at least one Event Type or enter date and user id to filter on audit events"
        );
        return;
      }
    }
    const params = {
      start_date: selectedStartDate
        ? moment(selectedStartDate).utc().format()
        : "",
      end_date: selectedEndDate ? moment(selectedEndDate).utc().format() : "",
      event_type_id: selectedEventTypes?.map((item) => item.value),
      event_type_code_id: selectedEventTypeCodes?.map((item) => item.value),
      event_action_code_id: selectedEventActionCodes?.map((item) => item.value),
      event_outcome_id: selectedEventOutcomeCodes?.map((item) => item.value),
      offSet: 0,
      limit: currentLimit,
      user_id: userId,
      sortFilter: columnMapping[sortValue],
      sortType: ascOrDescValue,
    };
    setCurrentOffset(0);
    setAuditParams(params);
    fetchAuditEvents(params);
  };

  useEffect(() => {
    fetchAuditSchema();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Audit Events</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Body>
                <Row>
                  <Col md="4">
                    <Form.Group>
                      <label>Start Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedStartDate
                            ? selectedStartDate.format("MM/DD/YYYY h:mm A")
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedStartDate}
                        onChange={(value) => {
                          setSelectedStartDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>End Date</label>
                      <CustomReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Not Selected",
                          value: selectedEndDate
                            ? selectedEndDate.format("MM/DD/YYYY h:mm A")
                            : "",
                        }}
                        timeFormat={true}
                        value={selectedEndDate}
                        onChange={(value) => {
                          setSelectedEndDate(value);
                        }}
                      ></CustomReactDatetime>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>Email Id</label>
                      <Form.Control
                        id="email_id"
                        placeholder="Email Id"
                        type="text"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label>Event Type Code Filter</label>
                      <Select
                        id="event_type_code"
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventTypeCodeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedEventTypeCodes}
                        onChange={(eventTypeCodes) => {
                          setSelectedEventTypeCodes(eventTypeCodes);
                          setSelectedEventTypes(
                            selectedEventTypes?.filter((x) => {
                              return (
                                eventTypeCodes?.filter((y) => {
                                  return x.event_type_code_id == y.value;
                                }).length > 0
                              );
                            })
                          );
                          setSelectedEventActionCodes([]);
                        }}
                        options={eventTypeCode}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Audit Event Type Filter</label>
                      <Select
                        id="event_type"
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventTypeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedEventTypes}
                        onChange={(eventTypes) => {
                          setSelectedEventTypes(eventTypes);
                          setSelectedEventActionCodes(
                            selectedEventActionCodes?.filter((x) => {
                              return (
                                eventTypes?.filter((y) => {
                                  return y.event_action_code_id == x.value;
                                }).length > 0
                              );
                            })
                          );
                        }}
                        options={auditEventType?.filter((x) => {
                          return (
                            selectedEventTypeCodes?.filter((y) => {
                              return y.value == x.event_type_code_id;
                            }).length > 0
                          );
                        })}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Event Action Code Filter</label>
                      <Select
                        id="event_action_code"
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventActionCodeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedEventActionCodes}
                        onChange={(eventActionCodes) => {
                          setSelectedEventActionCodes(eventActionCodes);
                        }}
                        options={eventActionCode.filter((x) => {
                          return (
                            selectedEventTypes?.filter((y) => {
                              return x.value == y.event_action_code_id;
                            }).length > 0
                          );
                        })}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label>Event Outcome Code Filter</label>
                      <Select
                        id="event_outcome"
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="eventOutcomeCodeFilter"
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedEventOutcomeCodes}
                        onChange={(eventOutcomeCodes) => {
                          setSelectedEventOutcomeCodes(eventOutcomeCodes);
                        }}
                        options={eventOutcomeCode}
                        isClearable={false}
                        placeholder="Not Selected"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Search
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                setSelectedStartDate(null);
                setSelectedEndDate(null);
                setUserId("");
                setSelectedEventTypes([]);
                setSelectedEventActionCodes([]);
                setSelectedEventOutcomeCodes([]);
                setSelectedEventTypeCodes([]);
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h5">Number of Audits: {totalCount}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={tableData}
                      totalCount={totalCount}
                      functionCallBack={fetchAuditEvents}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      columns={[
                        {
                          Header: "Event Type Code",
                          accessor: "event_type_code_text",
                          filterable: false,
                          showTooltip: true,
                          placement: "right",
                          minWidth: 220,
                        },
                        {
                          Header: "Event Audit",
                          accessor: "audit_event_type_text",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 220,
                        },
                        {
                          Header: "Event Action Code",
                          accessor: "event_action_code_text",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 180,
                        },
                        {
                          Header: "Event Outcome",
                          accessor: "event_outcome_text",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 150,
                        },
                        {
                          Header: "Email Id",
                          accessor: "email_id",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 250,
                        },
                        {
                          Header: "Remote Ip",
                          accessor: "remote_ip",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 150,
                        },
                        {
                          Header: "Event Timestamp",
                          accessor: "event_timestamp",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Participant Object Detail",
                          accessor: "actions",
                          filterable: false,
                          showTooltip: false,
                          minWidth: 220,
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AuditList;
