export const isStatusInAccessibleState = (status) => {
  /* 
    All available states
    DEPLOYING
    DEPLOYED
    DEPLOY_FAILED
    DESTROYING
    DESTROYED
    DESTROY_FAILED
  */
  const accessibleStates = [
    "DEPLOYED",
    "DEPLOY_FAILED",
    "DESTROY_FAILED",
  ];
  /* const nonAccessibleStates = [
    "DEPLOYING",
    "DESTROYING",
    "DESTROYED",
  ];
  if (nonAccessibleStates.includes(status)) return false; */
  if (accessibleStates.includes(status)) return true;

  return false;
};
