import React, { useState, useEffect } from "react";
import EULAModal from "./EULAModal";
import { Spinner } from "react-bootstrap";
import {
  fetchEulaUserConsent,
  fetchCurrentEula,
  addEulaUserConsent,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

const EULA = ({ children, user, signOut }) => {
  const [isEulaAccepted, setIsEulaAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [eulaVersionId, setEulaVersionId] = useState("");
  const [eulaContent, setEulaContent] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showEulaLoader, setShowEulaLoader] = useState(false);

  const getCurrentEula = async () => {
    try {
      setShowEulaLoader(true);
      const response = await API.graphql(graphqlOperation(fetchCurrentEula));
      if (response.data?.fetchCurrentEula?.success) {
        setEulaContent(response.data.fetchCurrentEula.body.content);
        setEulaVersionId(response.data.fetchCurrentEula.body.eula_version_id);
      } else {
        console.error(response.data?.fetchCurrentEula?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowEulaLoader(false);
    }
  };

  const addConsent = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(addEulaUserConsent, {
          user_id: user.username,
          eula_version_id: eulaVersionId,
        })
      );
      if (response.data?.addEulaUserConsent?.success) {
        setIsEulaAccepted(true);
        setIsModalOpen(false);
      } else {
        console.error(response.data?.addEulaUserConsent?.message);
      }
      return response.data?.addEulaUserConsent?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const handleDecline = () => {
    setIsModalOpen(false);
    signOut();
    // Handle decline logic, such as redirecting to a decline page
  };

  const checkEulaStatus = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(fetchEulaUserConsent, {
          user_id: user.username,
        })
      );
      if (response.data?.fetchEulaUserConsent?.code === "404") {
        getCurrentEula();
        setIsEulaAccepted(false);
        setIsModalOpen(true);
      } else {
        setIsEulaAccepted(true);
        setIsModalOpen(false);
        setEulaVersionId(
          response.data?.fetchEulaUserConsent?.body?.eula_version_id
        );
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    // Initial check
    checkEulaStatus();
    // Set up polling every 5 minutes (300000 milliseconds)
    const interval = setInterval(() => {
      checkEulaStatus();
    }, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  if (!isEulaAccepted) {
    if (showLoader || showEulaLoader) return <Spinner animation="grow" />;

    return (
      <EULAModal
        isOpen={isModalOpen}
        onAccept={addConsent}
        onDecline={handleDecline}
        eulaContent={eulaContent}
        signOut={signOut}
      />
    );
  }

  return <>{children}</>;
};

export default EULA;
