import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

const EULAModal = ({ isOpen, onAccept, onDecline, eulaContent }) => {
  return (
    <Modal isOpen={isOpen} toggle={onDecline} size="lg" backdrop="static" keyboard={false}>
      <ModalHeader>End User License Agreement</ModalHeader>
      <ModalBody className="modal-body-scroll">
        <div dangerouslySetInnerHTML={{ __html: eulaContent }} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onAccept}>
          Accept
        </Button>
        <Button color="secondary" onClick={onDecline}>
          Decline
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EULAModal;
