import { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";

import Select from "react-select";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
  Nav,
  Tab,
  Table,
} from "react-bootstrap";
import {
  getAvailablePorts,
  getEnvironmentConfiguration,
  updateEnvironment,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

const buttonRemoveCustom = {
  position: "relative",
  top: "27px",
  backgroundColor: "rgb(250 65 75)",
  color: "white",
  left: "10px",
};

function ChannelForm({
  index,
  isEdit,
  channelData,
  availablePorts,
  configChannelLibrary,
  onChange,
  onRemove,
  onReset,
}) {
  // Contexts
  /* const notify = useContext(NotificationAlertContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory(); */

  const addOrUpdate = isEdit ? "update" : "add";

  const channelTypeOptions = [
    {
      value: "HTTP",
      label: "HTTP Listener",
      name: "type",
    },
    {
      value: "TCP",
      label: "TCP/IP Listener",
      name: "type",
    },
    {
      value: "SFTP",
      label: "SFTP Listener",
      name: "type",
    },
    {
      value: "SFTP_POLL",
      label: "SFTP Poller",
      name: "type",
    },
    {
      value: "CHAN_READ",
      label: "Channel Reader",
      name: "type",
    },
    {
      value: "HTTP_S3",
      label: "HTTP S3 Listener",
      name: "type",
    },
    {
      value: "JS_READ",
      label: "JavaScript Reader",
      name: "type",
    },
    {
      value: "DB_READ",
      label: "Database Reader",
      name: "type",
    },
    {
      value: "EMAIL_READ",
      label: "Email Reader",
      name: "type",
    },
    {
      value: "DICOM_LIST",
      label: "DICOM Listener",
      name: "type",
    },
    {
      value: "FHIR_LIST",
      label: "FHIR Listener",
      name: "type",
    },
    {
      value: "HDH_LIST",
      label: "Health Data Hub Listener",
      name: "type",
    },

    {
      value: "WS_LIST",
      label: "Web Service Listener",
      name: "type",
    },
    {
      value: "PDQV2_LIST",
      label: "PDQ V2 Listener",
      name: "type",
    },
    {
      value: "PDQV3_LIST",
      label: "PDQ V3 Listener",
      name: "type",
    },
    {
      value: "PIXV2_LIST",
      label: "PIX V2 Listener",
      name: "type",
    },
    {
      value: "PIXV3_LIST",
      label: "PIX V3 Listener",
      name: "type",
    },
    {
      value: "XDSB_LIST",
      label: "XDS.b Listener",
      name: "type",
    },
  ];

  const authTypeOptions = [
    {
      value: "BASIC",
      label: "BASIC",
      name: "http_auth_type",
    },
    {
      value: "API_KEY",
      label: "API KEY",
      name: "http_auth_type",
    },
    {
      value: "MC_AUTH",
      label: "MC AUTH",
      name: "http_auth_type",
    },
  ];

  const supportTierOptions = [
    { label: "Low", value: 1, name: "support_tier" },
    { label: "Medium", value: 2, name: "support_tier" },
    { label: "Critical", value: 3, name: "support_tier" },
  ];

  // Create states to keep the information of selected values
  const [channelType, setChannelType] = useState(
    channelTypeOptions.find(
      (channelTypeOption) => channelTypeOption.value === channelData.type
    ) || { label: channelData.type }
  );
  const [channelAuthType, setChannelAuthType] = useState(
    authTypeOptions.find(
      (authTypeOption) => authTypeOption.value === channelData.http_auth_type
    )
  );
  const [channelPort, setChannelPort] = useState(null);
  const [channelSupportTier, setChannelSupportTier] = useState(
    supportTierOptions.find(
      (supportTierOption) =>
        supportTierOption.value === +channelData.support_tier
    )
  );

  useEffect(() => {
    if (channelData.port) {
      setChannelPort({
        label: channelData.port,
        value: channelData.port,
        name: "port",
      });
    }
  }, [channelData]);

  const getLibraryChannelName = (channel_library_id) => {
    for (const channelObj of configChannelLibrary) {
      if (channelObj.channel_library_id == channel_library_id) {
        return channelObj.channel_library_name;
      }
    }
    return "N/A";
  };

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>Deploy Channel Name</label>
                <Form.Control
                  id={`${addOrUpdate}-channelName-${index + 1}`}
                  placeholder="Deploy Channel Name"
                  disabled={isEdit}
                  name="path"
                  value={channelData.path || ""}
                  onChange={(e) => {
                    onChange("path", e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Library Channel Name</label>
                <Form.Control
                  id={`${addOrUpdate}-libraryChannelName-${index + 1}`}
                  placeholder="Library Channel Name"
                  disabled={true}
                  name="library_channel_name"
                  value={getLibraryChannelName(channelData.channel_library_id)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Channel Type</label>
                <Form.Control
                  id={`${addOrUpdate}-channelType-${index + 1}`}
                  placeholder="Channel Type"
                  disabled={true}
                  name="type"
                  value={channelType.label}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Auth Type</label>
                <Select
                  id={`${addOrUpdate}-authType-${index + 1}`}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="http_auth_type"
                  isDisabled={
                    isEdit ||
                    !channelType ||
                    (channelType.value !== "HTTP" &&
                      channelType.value !== "HTTP_S3" &&
                      channelType.value !== "FHIR_LIST" &&
                      channelType.value !== "HDH_LIST" &&
                      channelType.value !== "WS_LIST" &&
                      channelType.value !== "PDQV3_LIST" &&
                      channelType.value !== "PIXV3_LIST" &&
                      channelType.value !== "XDSB_LIST")
                  }
                  options={authTypeOptions}
                  placeholder="Auth Type"
                  value={channelAuthType}
                  onChange={(e) => {
                    onChange("http_auth_type", e.value);
                    setChannelAuthType(e);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Channel Port</label>
                <Select
                  id={`${addOrUpdate}-channelPort-${index + 1}`}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="port"
                  isDisabled={
                    isEdit ||
                    !channelType ||
                    !(
                      channelType.value === "HTTP" ||
                      channelType.value === "TCP" ||
                      channelType.value === "DICOM_LIST" ||
                      channelType.value === "FHIR_LIST" ||
                      channelType.value === "HDH_LIST" ||
                      channelType.value === "WS_LIST" ||
                      channelType.value === "PDQV2_LIST" ||
                      channelType.value === "PDQV3_LIST" ||
                      channelType.value === "PIXV2_LIST" ||
                      channelType.value === "PIXV3_LIST" ||
                      channelType.value === "XDSB_LIST"
                    )
                  }
                  options={availablePorts}
                  placeholder="Channel Port"
                  value={channelPort}
                  onChange={(e) => {
                    onChange("port", e.value);
                    setChannelPort(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>Queue Yellow Threshold (Count)</label>
                <Form.Control
                  placeholder="Queue Yellow Threshold (Count)"
                  type="number"
                  required
                  value={channelData.queue_yellow}
                  onChange={(e) => {
                    onChange("queue_yellow", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Queue Red Threshold (Count)</label>
                <Form.Control
                  placeholder="Queue Red Threshold (Count)"
                  type="number"
                  required
                  value={channelData.queue_red}
                  onChange={(e) => {
                    onChange("queue_red", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Error Yellow Threshold (Count)</label>
                <Form.Control
                  placeholder="Error Yellow Threshold (Count)"
                  type="number"
                  required
                  value={channelData.error_yellow}
                  onChange={(e) => {
                    onChange("error_yellow", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Error Red Threshold (Count)</label>
                <Form.Control
                  placeholder="Error Red Threshold (Count)"
                  type="number"
                  required
                  value={channelData.error_red}
                  onChange={(e) => {
                    onChange("error_red", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Form.Group>
                <label>Inactivity Yellow Threshold (Mins)</label>
                <Form.Control
                  placeholder="Inactivity Yellow Threshold (Mins)"
                  type="number"
                  required
                  value={channelData.inactivity_yellow}
                  onChange={(e) => {
                    onChange("inactivity_yellow", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Inactivity Red Threshold (Mins)</label>
                <Form.Control
                  placeholder="Inactivity Red Threshold (Mins)"
                  type="number"
                  required
                  value={channelData.inactivity_red}
                  onChange={(e) => {
                    onChange("inactivity_red", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Poll Frequency (Mins)</label>
                <Form.Control
                  placeholder="Poll Frequency (Mins)"
                  type="number"
                  required
                  value={channelData.poll_frequency}
                  onChange={(e) => {
                    onChange("poll_frequency", +e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group>
                <label>Monitoring Needs</label>
                <Select
                  id={`${addOrUpdate}-monitoringNeeds-${index + 1}`}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="support_tier"
                  value={channelSupportTier}
                  required
                  onChange={(e) => {
                    onChange("support_tier", e.value);
                    setChannelSupportTier(e);
                  }}
                  options={supportTierOptions}
                  placeholder="Monitoring Needs"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        {!isEdit && (
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button
                  className="btn-fill"
                  variant="danger"
                  onClick={onRemove}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        )}
        {isEdit && (
          <Card.Footer>
            <Row>
              <Col md="12">
                <Button
                  className="btn-fill"
                  variant="warning"
                  onClick={onReset}
                >
                  Reset Values
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        )}
      </Card>
    </>
  );
}

export default ChannelForm;
