import React from "react";
import Select from "react-select";
import { useState, useContext } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import { getUsers, getList } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import moment from "moment";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

function Users() {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const [clientOptions, setClientOptions] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [userNames, setUserName] = useState("");

  const [tableData, setTableData] = useState([]);

  const [isDisableUserChecked, setIsDisableUserChecked] = useState(false);
  const handleDisableCheckboxChange = () => {
    setIsDisableUserChecked(!isDisableUserChecked);
  };

  // Pagination Starts
  const columnMapping = {
    "Email Id": "email_id",
    "First Name": "first_name",
    "Last Name": "last_name",
    "Created At": "created_at",
    "Client": "client_name",
  };
  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Email Id");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  React.useEffect(() => {
    fetchList("CLIENTS");
  }, []);

  // Fetch options for clients, environments and channels
  const fetchList = async (type) => {
    try {
      const response = await API.graphql(
        graphqlOperation(getList, {
          type: type
        })
      );
      if (response.data?.getList?.success) {
        const options = response.data.getList
          ? response.data.getList.body
              .map((client) => {
                const option = {
                  value: client.id,
                  label: client.name,
                };
                return option;
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [];
        setClientOptions(options);
      } else {
        console.error(response.data?.getList?.message);
        notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch ${type.toLocaleLowerCase()} list.`);
    }
  };

  // Fetch Users
  const fetchUsers = async (variables = {}) => {
    try {
      setShowLoader(true);
      const params = {
        email_id: userEmailAddress,
        name: userNames,
        client_id: selectedClient?.value,
        is_hidden: isDisableUserChecked,
      };
      variables = { ...variables, ...params };
      const response = await API.graphql(graphqlOperation(getUsers, variables));
      if (response.data?.getUsers?.success) {
        const users = _.get(response, "data.getUsers.body.user_data", []);
        const totalCount = response.data.getUsers.body.total_count;
        setTotalCount(totalCount);
        const tableData = users?.map((y, key) => {
          return {
            email_id: y.email_id,
            first_name: y.first_name,
            last_name: y.last_name,
            created_at: moment(y.created_at).format("YYYY-MM-DD HH:mm:ss"),
            client_name: y.client_name ? y.client_name : "-",
            editUser: (
              <Link
                className="btn btn-info"
                type="button"
                variant="primary"
                to={{
                  pathname: "/admin/users/create-update-user",
                  state: {
                    user_id: y.user_id,
                    notify_user: y.notify_user,
                    email_id: y.email_id,
                    first_name: y.first_name,
                    last_name: y.last_name,
                    is_hidden: y.is_hidden,
                    client_id: y.client_id,
                  },
                }}
              >
                <i className="fas fa-pen"></i> Edit User
              </Link>
            ),
          };
        });
        setTableData(tableData);
        notify("success", `Users Fetched Successfully`);
      } else {
        console.error(response.data?.getUsers?.message);
        notify("danger", `Could not fetch Users.`);
      }
    } catch (error) {
      console.error(error);
      notify("danger", `Could not fetch Users.`);
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>User List</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="10">
                    
                  </Col>
                  <Col md="2">
                    <Link
                      className="btn-round btn-info"
                      type="submit"
                      variant="primary"
                      style={{ float: "right" }}
                      to={{
                        pathname: "/admin/users/create-update-user",
                      }}
                    >
                      <i className="fas fa-plus"></i> Create User
                    </Link>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="4">
                    <Form.Group>
                      <label>User's Email Address</label>
                      <Form.Control
                        placeholder="User's Email Address"
                        type="email"
                        value={userEmailAddress}
                        onChange={(e) => setUserEmailAddress(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                      <label>User Name</label>
                      <Form.Control
                        placeholder="User Name"
                        type="text"
                        value={userNames}
                        onChange={(e) => setUserName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group>
                    <label>Client</label>
                      <Select
                        id="client"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="clientList"
                        value={selectedClient}
                        onChange={(event) => {
                          setSelectedClient({
                            value: event.value,
                            label: event.label,
                          });
                        }}
                        options={clientOptions}
                        placeholder="None Selected"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <input
                        type="checkbox"
                        checked={isDisableUserChecked}
                        onChange={handleDisableCheckboxChange}
                        style={{ width: "25px", height: "25px" }}
                      />
                      <label
                        style={{
                          position: "relative",
                          top: "-7px",
                          left: "5px",
                        }}
                      >
                        View Disabled User
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={(e) => {
                const filterObj = {
                  offSet: 0,
                  limit: currentLimit,
                };
                setCurrentOffset(0);
                fetchUsers(filterObj);
              }}
            >
              Search
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={() => {
                setUserEmailAddress("");
                setUserName("");
                setIsDisableUserChecked(false);
                setSelectedClient(null);
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h5">Number of Users: {totalCount}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={tableData}
                      totalCount={totalCount}
                      functionCallBack={fetchUsers}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      columns={[
                        {
                          Header: "Email Id",
                          accessor: "email_id",
                          filterable: false,
                          minWidth: 300,
                          showTooltip: true,
                          placement: "right"
                        },
                        {
                          Header: "First Name",
                          accessor: "first_name",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Last Name",
                          accessor: "last_name",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Created At",
                          accessor: "created_at",
                          filterable: false,
                          showTooltip: true,
                          minWidth: 200,
                        },
                        {
                          Header: "Client",
                          accessor: "client_name",
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Edit users",
                          accessor: "editUser",
                          filterable: false,
                          showTooltip: false,
                          sortable: false,
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      ;
    </>
  );
}

export default Users;
